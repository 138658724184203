import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Register } from "../../screens/auth/RegisterScreen";
import { Login } from "../../screens/auth/LoginScreen";
import { Home } from "../../screens/home/Home";
import { ForgotPassword } from "../../screens/auth/ForgotPasswordScreen";
import { EmailCheck } from "../../screens/auth/EmailCheck";
import { NewPassword } from "../../screens/auth/NewPassword";
import { RequireAuth } from "../../hoc/require-auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAuthUser,
  resetInitializeLoading,
  resetPasswordRequest,
} from "../../store/reducers/auth";
import { Profile } from "../../screens/profile/Profile";
import PrivacyPolicy from "../../screens/profile/PrivacyPolicy";
import { PrivacyPolicy as AuthPrivacyPolicy } from "../../screens/auth/PrivacyPolicy";
import TermsCondition from "../../screens/profile/TermsCondition";
import { TermsCondition as AuthTermsCondition } from "../../screens/auth/TermsCondition";
import Location from "../../screens/profile/Location";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import DashboardLayout from "../DashboardLayout";
import CustomizedTables from "../DashboardTable";
import DashBoardPage from "../../screens/DashboardPage";
import { ResetPassword } from "../../screens/auth/ResetPassword/ResetPassword";
import ResetSuccess from "../../screens/auth/ResetPassword/ResetSuccess";
import PageLayout from "../../Layouts/PageLayouts";
import OrderDetails from "../../screens/OrderDetail/OrderDetails";
import Header from "../header/Header";
import OrderHistory from "../../screens/OrderDetail/OrderHistory";

import NotificationPage from "../../screens/Notification";
import { toast } from "react-toastify";
import Subscription from "../../screens/subscription/Subscription";
import SuccessPayment from "../SuccessPayment/SuccessPayment";
import FailurePayment from "../FailurePayment/FailurePayment";
import { RequireSubscription } from "../../hoc/require-subscription";
import { I18nextProvider } from "react-i18next";
import i18n from "../../utils/i18n";
import Feedback from "../../screens/profile/Feedback";
import { getAllNotification } from "../../store/reducers/notification";

export function App() {
  const dispatch = useDispatch();

  // messaging.onBackgroundMessage((payload) => {
  //   console.log(
  //     "[firebase-messaging-sw.js] Received background message ",
  //     payload
  //   );
  //   const notificationTitle = payload.notification.title;
  //   const notificationOptions = {
  //     body: payload.notification.body,
  //     icon: payload.notification.image,
  //   };

  //   self.registration.showNotification(notificationTitle, notificationOptions);
  // });

  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data === "reload" || event.data.command === "reload") {
        console.log("reloading");
        window.location.reload();
      }
    });
    const token = localStorage.getItem("@token");
    if (token) {
      dispatch(getAuthUser());
    } else {
      dispatch(resetInitializeLoading());
    }
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: "Poppins",
    },
    components: {},
  });

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route path="/register" element={<Register />} />
            <Route
              path="/subscription"
              element={
                <RequireAuth>
                  <Subscription />
                </RequireAuth>
              }
            />
            <Route
              path="/feedback"
              element={
                <RequireAuth>
                  <Feedback />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/checkyouremail" element={<EmailCheck />} />
            <Route path="/newpassword" element={<NewPassword />} />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            {/* <Route path="/auth/privacy-policy" element={<AuthPrivacyPolicy />} /> */}
            {/* <Route
            path="/auth/terms-condition"
            element={<AuthTermsCondition />}
          /> */}
            <Route
              path="/privacy-policy"
              element={
                <RequireAuth>
                  <PrivacyPolicy />
                </RequireAuth>
              }
            />
            <Route
              path="/terms-condition"
              element={
                <RequireAuth>
                  <TermsCondition />
                </RequireAuth>
              }
            />
            <Route path="/location" element={<Location />} />
            <Route
              path="/dashboard"
              element={
                <RequireSubscription>
                  <DashBoardPage />
                </RequireSubscription>
              }
            />
            <Route path="/reset" element={<ResetPassword />} />
            <Route
              path="/order-history"
              element={
                <RequireSubscription>
                  <OrderHistory />
                </RequireSubscription>
              }
            />
            <Route
              path="/ViewDetails"
              element={
                <RequireSubscription>
                  <OrderDetails />
                </RequireSubscription>
              }
            />
            <Route
              path="/notification"
              element={
                <RequireSubscription>
                  <NotificationPage />
                </RequireSubscription>
              }
            />
            <Route path="/success" element={<SuccessPayment />} />
            <Route path="/failure" element={<FailurePayment />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
